import { useLoggedIn } from 'common/useLoggedIn';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getUsersCSV, useLazySearchUsersQuery } from '../../common/api';
import UsersSearchForm from './UsersSearchForm';
import UsersTable from './UsersTable';
import 'pages/login/login.css';
import Navigation from 'pages/Navigation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SearchFormData } from './types';

export default function UsersScreen() {
  const [loadingCSV, setLoadingCSV] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [
    searchUsers,
    {
      data: { users = undefined, count = undefined } = {},
      isFetching: loadingUsers,
    },
  ] = useLazySearchUsersQuery();

  const data = useMemo<SearchFormData>(() => {
    return {
      email: searchParams.get('email') ?? '',
      discountCode: searchParams.get('coupon') ?? '',
      batchId: searchParams.get('batchId') ?? '',
      name: searchParams.get('name') ?? '',
      barcode: searchParams.get('barcode') ?? '',
      page:
        searchParams.get('page') !== null
          ? parseInt(searchParams.get('page')!)
          : 0,
    };
  }, [searchParams]);

  useEffect(() => {
    searchUsers(data, true);
  }, [data, searchUsers]);

  const onSearchPressed = useCallback(
    (
      email: string,
      discountCode: string,
      batchId: string,
      name: string,
      barcode: string
    ) => {
      setSearchParams({
        email,
        coupon: discountCode,
        batchId,
        name,
        barcode,
        page: '0',
      });
    },
    [setSearchParams]
  );

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setSearchParams((params) => {
        params.set('page', pageNumber.toString());
        return params;
      });
    },
    [setSearchParams]
  );

  const onCSVPressed = useCallback(
    (
      email: string,
      discountCode: string,
      batchId: string,
      name: string,
      barcode: string
    ) => {
      if (!email && !discountCode && !batchId && !name && !barcode) {
        return;
      }

      setLoadingCSV(true);
      getUsersCSV({ email, discountCode, batchId, name, barcode }).finally(() =>
        setLoadingCSV(false)
      );
    },
    []
  );

  const loggedIn = useLoggedIn();
  const navigate = useNavigate();

  if (loggedIn === false) {
    navigate('/');
  }

  return (
    <div className="fullPageContainer">
      <Navigation />

      <UsersSearchForm
        onSearchPressed={onSearchPressed}
        onCSVPressed={onCSVPressed}
        searching={loadingUsers}
        loadingCSV={loadingCSV}
        {...data}
      />

      {users && count !== undefined && (
        <UsersTable
          users={users}
          rowCount={count}
          onPageChange={onPageChange}
          page={data.page}
        />
      )}
    </div>
  );
}
