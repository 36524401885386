import { createSlice } from '@reduxjs/toolkit';

interface State {
  loggedIn?: boolean;
}

const state: State = {
  loggedIn: undefined,
};

const auth = createSlice({
  name: 'auth',
  initialState: state,
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
  },
});

export default auth.reducer;

export const authActions = auth.actions;
