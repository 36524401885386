import ReactDOM from 'react-dom/client';
import './index.css';
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginScreen from './pages/login/LoginScreen';
import UsersScreen from 'pages/users/UsersScreen';
import { StyledEngineProvider } from '@mui/material/styles';
import UserDetailsScreen from 'pages/users/UserDetailsScreen';
import { Provider } from 'react-redux';
import store, { useAppDispatch, useAppSelector } from 'store/store';
import DiscountsScreen from 'pages/discounts/DiscountsScreen';
import UsersHelpPage from 'pages/tutorial/UsersHelpPage';
import DiscountsHelpPage from 'pages/tutorial/DiscountsHelpPage';
import CouponsHelpPage from 'pages/tutorial/CouponsHelpPage';
import reportWebVitals from 'reportWebVitals';
import React, { useEffect, useMemo, useState } from 'react';
import { apiSignOutController } from 'common/client';
import { Auth } from 'aws-amplify';
import { authActions } from 'store/reducers/auth';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8d09f7',
    },
  },
});

const App = () => {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);

  const dispatch = useAppDispatch();

  useEffect(() => {
    apiSignOutController.signOut = () => {
      Auth.signOut().finally(() => {
        dispatch(authActions.setLoggedIn(false));
      });
    };
  }, [dispatch]);

  useEffect(() => {
    Auth.currentSession()
      .then((credentials) => {
        dispatch(authActions.setLoggedIn(credentials.isValid()));
      })
      .catch(() => {
        dispatch(authActions.setLoggedIn(false));
      });
  }, [dispatch]);

  const loggedOutRouter = useMemo(() => {
    return createBrowserRouter([{ path: '/*', element: <LoginScreen /> }]);
  }, []);

  const loggedInRouter = useMemo(() => {
    return createBrowserRouter([
      { path: '/*', element: <UsersScreen /> },
      { path: '/discounts', element: <DiscountsScreen /> },
      { path: '/users/:uuid', element: <UserDetailsScreen /> },
      { path: '/help_users', element: <UsersHelpPage /> },
      { path: '/help_discounts', element: <DiscountsHelpPage /> },
      { path: '/help_discounts_edit', element: <CouponsHelpPage /> },
    ]);
  }, []);

  if (loggedIn === undefined) {
    return null;
  }

  if (loggedIn) {
    return <RouterProvider router={loggedInRouter} />;
  }

  return <RouterProvider router={loggedOutRouter} />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
