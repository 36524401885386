import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import useDev from 'common/useDev';
import { useLoggedIn } from 'common/useLoggedIn';
import { useCallback, useState } from 'react';
import { login } from './actions';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { authActions } from 'store/reducers/auth';

export default function LoginScreen() {
  const dispatch = useAppDispatch();

  const dev = useDev();

  const [email, setEmail] = useState(dev ? 'oo@oo.com' : '');
  const [password, setPassword] = useState(dev ? 'Password@2' : '');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onLoginPressed = useCallback(() => {
    setLoading(true);
    login(email, password)
      .then(() => {
        dispatch(authActions.setLoggedIn(true));

        setLoading(false);
        navigate('/users', { replace: true });
      })
      .catch(() => {
        setLoading(false);
        alert('Invalid credentials');
      });
  }, [dispatch, email, navigate, password]);

  return (
    <Grid
      className="container"
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item container direction="column" xs={10} md={4}>
        <TextField
          label="Email"
          className="textField"
          variant="standard"
          type="email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
        <TextField
          label="Password"
          className="textField marginTop20"
          variant="standard"
          type="password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
        />
        <LoadingButton
          variant="contained"
          className="loginButton"
          onClick={onLoginPressed}
          disabled={!email || !password}
          loading={loading}
        >
          Login
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
