import { Amplify, Auth } from 'aws-amplify';

type Environment = typeof production;

const alpha = {
  amplify: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_A24fw6S6u',
    userPoolWebClientId: '1f6ar791d6s1dk1mpar4rm1c0b',
  },
  apiURL: 'https://alpha.app.iamyiam.com/api/v2/admin',
};

const localAlpha = {
  ...alpha,
  apiURL: 'http://localhost:8000/api/v2/admin',
};

const production = {
  amplify: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_6YqPkSazd',
    userPoolWebClientId: '3de8prgg2khpp20b8gco312995',
  },
  apiURL: 'https://www.iamyiam.com/api/v2/admin',
};

const localProduction: Environment = {
  ...production,
  apiURL: 'http://localhost:8000/api/v2/admin',
};

const environments = {
  localAlpha,
  alpha,

  localProduction,
  production,
};

const SELECTED_ENVIRONMENT = process.env[
  'REACT_APP_ENVIRONMENT'
] as keyof typeof environments;

export const environment = environments[SELECTED_ENVIRONMENT];

Amplify.configure({
  Auth: environment.amplify,
});

export const login = async (email: string, password: string) => {
  await Auth.signIn(email, password);

  return {};
};
